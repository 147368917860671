export default function reducer(state = {
  deviceShadow: [],
  fetching: false, 
  fetched: false, 
  error: null,
  pageCount: 0
}, action) {
  switch (action.type) {
    case "FETCH_DEVICE_SHADOW": {
      return {...state, fetching: true}
    }
    case "FETCH_DEVICE_SHADOW_REJECTED": {
      return {...state, fetching: false, error: 500}
    }
    case "FETCH_DEVICE_SHADOW_FULFILLED": {
      return {...state, fetching: false, fetched: true, error: null, deviceShadow: action.payload.data}
    }
    case "CLEAR_DEVICE_SHADOW_DATA": {
      return { ...state, deviceShadow: [], fetching: false, fetched: false, error: null }
    }
  }
  return state
}
