
export default function reducer(state = {
  percentage : 0
}, action) {
  switch (action.type) {
    case 'UPDATE_PERCENTAGE':{
        return {
            ...state,
            percentage: action.payload
        }
    }
  }
  return state
}
