export default function reducer(state=[], action) {

const { payload, type } = action;
    switch (type) {
        case 'ADD_TOAST':{
            return [payload, ...state];
        }
        case 'REMOVE_TOAST':{
            return state.filter(toast => toast == "NA");
        }
        default:
            return state;
    }
}
