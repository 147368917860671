import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'
import { signOutUser } from "../../actions/AuthAction"
import jwt_decode from 'jwt-decode';
import moment from 'moment-timezone';
import PIPContainer from "../../pages/PIPContainer";
//import FontResizer from "../subcomponents/FontResizer";

@connect((store) => {
  return {
    auth: store.auth,
  };
})

export const PrivateRoute = ({component: ComposedComponent, ...rest}) => {
  class Authorization extends React.Component{
    handleRender(props) {
      if (!this.props.auth.isAuthenticated) {
        return <Redirect to={{ pathname: '/login', state: { from: props.location, message: 'You need to sign in' } }}/>
      }  else {
          if(this.authExpiryCheck()) {
            return <ComposedComponent {...props}/>
          } else{
            this.props.dispatch(signOutUser());
            return <Redirect to={{ pathname: '/login', state: { from: props.location, message: 'You need to sign in' } }}/>
          }
        }
    }
    authExpiryCheck() {
      const token = localStorage.getItem('iTramsToken')
      if (token) {
        var decoded = jwt_decode(token);
        if(moment.utc(new Date())>moment.utc(new Date(decoded.exp*1000))) {
          return false;
        } else {
          return true;
        }
      }
    }

    render() {
      return [
        <Route {...rest} key="routes" render={this.handleRender.bind(this)}/>
      ]
    }
  }

  return <div className="h-100 d-flex no-scroll"><PIPContainer /><Authorization /></div>
  //return <div className="h-100 d-flex"><FontResizer /><PIPContainer /><Authentication /></div>
}
