import { applyMiddleware, createStore } from "redux"
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { showToast, showToastError } from "./library/utility";

//import reducer from "./reducers"
import * as reducer from "./reducers";
const promiseMiddleware = store => next => action => {
  if (action.payload && action.payload.messageDisplay) {
    if (action.payload.status == 200) {
      action.payload.data.data.responseMessage.map(value => {
        if (value.type == "SUCCESS") showToast(value.message);
        else showToastError(value.message);
      });
    }
    if (action.payload.status == 500) {
      action.payload.data.data.responseMessage.map(value => {
        showToastError(value.message);
      });
    }
  }
  next(action);
};
const middleware = applyMiddleware(promiseMiddleware, promise(), thunk);
export default createStore(reducer.rootReducer, middleware);
