import { insertObjectAtFirstPosition, filterByValue, fetchLastinsertedArrayFirst } from '../../library/utility';

export default function reducer(state = {
  campaigns: [],
  fetching: false, 
  fetched: false, 
  error: null,
  pageCount: 0,
  deviceFilters: [],
  softwares: [],
  softwaresNw:[],
  deviceCountFromFilterID:0,
  deviceCountFetching: false, 
  deviceCountFetched: false, 
}, action) {
  switch (action.type) {
    case "FETCH_CAMPAIGN": {
      return {...state, fetching: true}
    }
    case "FETCH_CAMPAIGN_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_CAMPAIGN_FULFILLED": {
      const campaignList = fetchLastinsertedArrayFirst(action.payload.data.data);
      return {...state, fetching: false, fetched: true, campaigns: campaignList}
    }
    case "FETCH_SEARCH_CAMPAIGN": {
      return {...state, fetching: true}
    }
    case "FETCH_SEARCH_CAMPAIGN_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_SEARCH_CAMPAIGN_FULFILLED": {
      const campaignText= action.payload.request.campaignTextSearch;
      const campaigns = action.payload.response.data.data;
      let campaignList = fetchLastinsertedArrayFirst(campaigns);
      if(campaignText !== undefined && campaignText !== null && campaignText !== "") {
        campaignList = filterByValue(campaigns, campaignText.replace('*',''));
      }
      return {...state, fetching: false, fetched: true, campaigns: campaignList}
    }
    case "CREATE_CAMPAIGN_FULFILLED": {
      const campaign = action.payload.data.data.data;
      const newCampaign = insertObjectAtFirstPosition(state.campaigns, campaign);
      return { ...state, fetching: false, fetched: true, campaigns: newCampaign  }
    }
    case "CREATE_CAMPAIGN_REJECTED": {
      return { ...state }
    }
    case "UPDATE_CAMPAIGN_SUCCESS":{
      const id = action.payload.request.data[0].id;
      if(id !== null) {
        const oldCampaignList = state.campaigns;
        const newCampaignList = action.payload.response.data.data;
        const campaign = oldCampaignList.map(obj => newCampaignList.find(o => o.id === obj.id) || obj);
        return { ...state, fetching: false, fetched: true, campaigns:campaign }
      }
    }
    case "UPDATE_CAMPAIGN_REJECTED":{
      return { ...state, fetching: false, error: action.payload }
    }
    case "FETCH_DEVICE_FILTER_SUCCESS": {
      const deviceFilterList = fetchLastinsertedArrayFirst(action.payload.data.data);
      return {...state, deviceFilters:deviceFilterList }
    }
    case "FETCH_DEVICE_FILTER_REJECTED": {
      return {...state, error: action.payload}
    }
    case "FETCH_SOFTWARE_SUCCESS": {
      //filter out software which does not have any files uploaded
      const filteredSoftwares = action.payload.data.data.filter(obj => obj.software_file_name !== null && obj.software_file_size !== null);
      const softwareList = fetchLastinsertedArrayFirst(filteredSoftwares);
      return {...state, fetching: false, fetched: true, softwares: softwareList}
    }
    case "FETCH_SOFTWARE_NW_SUCCESS": {
     
      //filter out software which does not have any files uploaded
     
      // var softwareList = [...action.payload];
      // console.log(softwareList);
      // debugger
      return {...state, fetching: false, fetched: true, softwaresNw: action.payload.data}
    }
    case "FETCH_SOFTWARE_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "DELETE_CAMPAIGN_SUCCESS": {
      const id = action.payload.request.data[0].id;
      if(id !== null) {
        const oldCampaignList = state.campaigns;
        const newCampaignList = [];
        if(oldCampaignList !== undefined && oldCampaignList.length > 0) {
            for(let i=0;i<=oldCampaignList.length-1;i++) {
                if(oldCampaignList[i].id === id) {
                    delete oldCampaignList[i];
                } else {
                    newCampaignList.push(oldCampaignList[i]);
                }
            }
        }
        return { ...state, fetching: false, fetched: true, campaigns: newCampaignList }
      }
    }
    case "DELETE_CAMPAIGN_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "CLEAR_CAMPAIGN_DATA": {
      return { ...state, campaigns: [], fetching: false, fetched: false, error: null, pageCount: 0, deviceFilters: [], softwares: [], deleteStatus:"", canceledCount:0, failedCount:0, in_progressCount:0, queuedCount:0, rejectedCount:0,  removedCount:0, succeededCount:0, timed_outCount:0, deviceCountFromFilterID:0 }
    }
    case "CLEAR_DEVICE_COUNT": {
      return { ...state, deviceCountFromFilterID:0 }
    }
    case "FETCH_DEVICE_COUNT_FILTERID": {
      return {...state, deviceCountFetching: true, deviceCountFetched: false }
    }
    case "FETCH_DEVICE_COUNT_FILTERID_REJECTED": {
      return {...state, deviceCountFetching: false, deviceCountFetched: false, error: action.payload }
    }
    case "FETCH_DEVICE_COUNT_FILTERID_FULFILLED": {
      return {...state, deviceCountFetching: false, deviceCountFetched: true, deviceCountFromFilterID: action.payload.data }
    }
  }
  return state
}
