import { getPageCount } from '../../library/utility';

export default function reducer(state = {
  deviceRolldown: [],
  fetching: false, 
  fetched: false, 
  error: null,
  pageCount: 0,
  deviceRolldownCount: 0,
  software: []
}, action) {
  switch (action.type) {
    case "FETCH_DEVICE_ROLLDOWN": {
      return {...state, fetching: true}
    }
    case "FETCH_DEVICE_ROLLDOWN_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_DEVICE_ROLLDOWN_FULFILLED": {
      const deviceRolldownCount = state.deviceRolldownCount + action.payload.response.data.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(deviceRolldownCount, perPage);
      return {...state, fetching: false, fetched: true, error: null, pageCount:numberOfPages, deviceRolldown: action.payload.response.data}
    }
    case "CLEAR_DEVICE_ROLLDOWN_DATA": {
      return { ...state, deviceRolldown: [], fetching: false, fetched: false, error: null }
    }
    case "FETCH_DEVICE_SOFTWARE_REJECTED": {
      return {...state,  error: action.payload}
    }
    case "FETCH_DEVICE_SOFTWARE_FULFILLED": {
      //let newSoftware = softwares.filter(obj => obj.module_type === action.payload.request.moduleType);
      return { ...state, fetching: false, fetched: true, software: action.payload.response.data }
    }
    case "CLEAR_DEVICE_SOFTWARE_DATA": {
      return { ...state, software: [] }
    }
    case "UPDATE_DEVICE_ROLLDOWN_SUCCESS": {
      const selectedSoftwareID = action.payload.softwareID;
      const newdeviceRolldownList = [action.payload.response.data];
      const olddeviceRolldownList = state.deviceRolldown;
      const updatedDeviceRolldown = olddeviceRolldownList.map(obj => newdeviceRolldownList.find(o => selectedSoftwareID === obj.id) || obj)
      return { ...state, fetching: false, fetched: true, deviceRolldown:updatedDeviceRolldown }
    }
    case "UPDATE_DEVICE_ROLLDOWN_REJECTED":{
      return { ...state, fetching: false, error: action.payload}
    }
  }
  return state
}
