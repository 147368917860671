var axios = require('axios');
//const CancelToken = axios.CancelToken
import * as Constants from "../../constants/Constants";

var axiosInstance = axios.create({
  //baseURL: process.env.API_URL,
  baseURL : Constants.HOST,
  timeout: 10000000,
//   cancelToken: new CancelToken(function (cancel) {
//  })
});

axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('iTramsToken');

module.exports = axiosInstance;
