import { addToast, initializeToast } from "../actions/ToastsAction";
import { signOutUser } from "../actions/AuthAction";
import store from "../store";
import autorefresh from "jwt-autorefresh";
import axios from "./axiosInstance";
import * as Constants from "../../constants/Constants";

let id = 0;

export function jwtRefreshtoken(){
  if(!store.getState().auth.isIdeal){
    const reftesh_token = localStorage.getItem('iTramsRefreshToken');
    const token = localStorage.getItem('iTramsToken');
    const request = {
      "grant_type":"refresh_token",
      "refresh_token":reftesh_token
    }
    const params = Object.keys(request).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(request[key]);
    }).join('&');
    const refresh = () => {
      return axios.post(`/oauth/token`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": "Basic QUtJQVFSUUxFSlo1TldIRkNJWlg6QUtJQVFSUUxFSlo1TldIRkNJWlg=",
        }
      }).then((response) => {
        let new_token = response.data.access_token;
        localStorage.setItem('iTramsToken',new_token);
        let new_refresh_token = response.data.refresh_token;
        localStorage.setItem('iTramsRefreshToken', new_refresh_token);
        let new_bearer_token = "Bearer "+new_token;
        axios.defaults.headers.common['Authorization'] = new_bearer_token;
        return new_token;
      })
      .catch((err) => {
        //store.dispatch({ type: "AUTH_REJECTED_DISP_MES", payload: "Sorry something went wrong! Please Login again.." });
        store.dispatch(signOutUser());
      })
    }
    const leadSeconds = () => {
      /** Generate random additional seconds (up to 30 in this case) to append to the lead time to ensure multiple clients dont schedule simultaneous refresh */
      const jitter = Math.floor(Math.random() * 30)

      /** Schedule autorefresh to occur 60 to 90 seconds prior to token expiration */
      return 60 + jitter
    }
  
    let start = autorefresh({ refresh, leadSeconds })
    start(token);
  } else {
    //store.dispatch({ type: "AUTH_REJECTED_DISP_MES", payload: "Session Timed Out! Please Login again.." });
    store.dispatch(signOutUser());
  }
}

export function createSnackbar(options) {
  return { ...options, id: id++ };
}
export function initToast(msg,showProgressbar) {
  store.dispatch(initializeToast({ text: msg,showProgressbar:showProgressbar }));
}
export function showToast(msg) {
  store.dispatch(addToast({ text: msg }));
}
export function showToastError(msg) {
  store.dispatch(addToast({ text: msg, err: true }));
}

//check undefined function or value
export function isUndefined(value) {
  return (value === undefined);
}

//check empty function or value
export function isEmpty(value) {
  return (value === "" || value === null || (typeof(value) === "string" && value.trim() === ''));
}

//check if length is zero
export function isZero(value) {
  return (value.length === 0);
}

//update or remove erorr message
export function updateErrorMessage(errorMessage, errorMessages, errorFieldLabel){
  if(errorMessage === null) { //If the validation is success, remove any error message if already exists
    if(errorMessages[errorFieldLabel] !== undefined) {
      delete errorMessages[errorFieldLabel];
    }
  } else {
    errorMessages[errorFieldLabel] = errorMessage;
  }
}

//sort element in column click remove
export function removeSortClassHandler(elements, e) {
  Object.values(elements).forEach(ele => {
    if (ele != e.currentTarget) {
      ele.classList.remove("sort_asc");
      ele.classList.remove("sort_desc");
    }
  })
}

export function filterByValue(array, string) {
  return array.filter(o =>
    Object.keys(o).some(k => typeof o[k] === 'string' ? o[k].toLowerCase().includes(string.toLowerCase()) : null)
  );
}

export function combineTwoJavascriptObjects(obj, variable) {
  for (let key of Object.keys(obj)) {
    if (!variable[key]) variable[key] = {};
    for (let innerKey of Object.keys(obj[key]))
      variable[key][innerKey] = obj[key][innerKey];
  }
}

export function mergeTwoJavascriptObjects(obj, src) {
  Object.keys(src).forEach(function(key) { obj[key] = src[key]; });
  return obj;
}

export function convertArrayToObject(array){
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {...obj, item };
  }, initialValue);
};

export function fetchsortedArray(obj, key, order) {
  if(key === "modified_at"){
    obj.sort((a,b) => {
      return new Date(b.modified_at) - new Date(a.modified_at)
    });
  } else {
    obj.sort((a,b) => (a[key] > b[key]) ? 1 : (b[key] > a[key]) ? -1 : 0);
  }
  if(order === "ascending") {
    return obj;
  } else {
    return obj.reverse();
  }
}

export function fetchLastinsertedArrayFirst(obj) {
  obj.sort((a, b) => {
    return new Date(b.modified_at) - new Date(a.modified_at);
  })
  return obj;
}

export function getRequestObject(pageNumber, sortingAttribute, direction, textToFilter) {
  var request = {
    direction: direction,
    sortingAttribute: sortingAttribute,
    pageSize: Constants.PAGESIZE,
    recordsCountToFetch: Constants.BATCHSIZE,
    pageNumber: pageNumber,
    textToFilter: textToFilter
  };
  return request;
}

export function getArrayOfObjectEntries(object){
  let arrayToReturn = [];
  if(object != undefined){
    let keys = Object.keys(object);
    for(let iter=0; iter< keys.length; iter++){
      arrayToReturn[iter] = object[keys[iter]];
    }
  }
  return arrayToReturn;
}

export function updateOldList(oldObjList, newObj) {
  if (oldObjList.length == undefined) {
    oldObjList = Object.values(oldObjList);
  }
  var newObjList = newObj.data.data.dataModel.data;

  let request = newObj.request;
  let insertIndex = request.pageNumber * request.recordsCountToFetch;
  if (newObjList.length == undefined) {
    newObjList = Object.values(newObjList);
  }
  var updatedOldObjList = getMergedList(oldObjList, newObjList, insertIndex);
  return updatedOldObjList;
}

export function getMergedList(oldObjList, newObjList, insertIndex) {
  const newObjLength = newObjList.length;
  for (var i = 0; i < newObjLength; i++, insertIndex++) {
    oldObjList[insertIndex] = newObjList[i];
  }
  return oldObjList;
}

export function getPageCount(totalObjectLength, pageSize) {
  let numberOfPages = Math.floor(totalObjectLength / pageSize);
  if (totalObjectLength % pageSize > 0) {
    numberOfPages = numberOfPages + 1;
  }
  return numberOfPages;
}

export function insertObjectAtFirstPosition(oldObjectlist, object) {
  var newObjectList = [];
  newObjectList = newObjectList.concat(object);
  if (oldObjectlist.length > 0) {
    newObjectList = newObjectList.concat(oldObjectlist);
  }
  return newObjectList;
}

export function getIndexOfParticularElemInArray(objectList, object) {
  let index = -1;
  if (objectList !== undefined) {
    for (let i = 0; i < objectList.length; i++) {
      let localObj = objectList[i];
      if (localObj.encryptedId == object.encryptedId) {
        index = i;
        break;
      }
    }
  }
  return index;
}

export function getDeepCopyArrayObject(existingArray) {
  let clonedArray = [];

  for (let i = 0; i < existingArray.length; i++) {
    let clonedObj = Object.assign({}, existingArray[i]);
    clonedArray.push(clonedObj);
  }
  return clonedArray;
}

// export function sortAlphabetically(obj) {
//   obj.sort(function(a, b){
//     if(a.value < b.value) { return -1; }
//     if(a.value > b.value) { return 1; }
//     return 0;
//   })
// }