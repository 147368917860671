export function fetchPip() {
  return (dispatch) => {
    dispatch({type: "FETCH_PIP"});
  }
}

export function addPip(pipObj) {
  return (dispatch) => {
    dispatch({type: "ADD_TO_PIP", payload: pipObj});
  }
}
export function removePip(index) {
  return (dispatch) => {
    dispatch({type: "REMOVE_PIP", payload: index});
  }
}
