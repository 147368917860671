import { getPageCount } from '../../library/utility';

export default function reducer(state = {
  devices: [],
  acceptedDevices: [],
  deviceCount: 0,
  fetching: false, 
  fetched: false, 
  error: null,
  deviceNextToken:"",
}, action) {
  switch (action.type) {
    case "FETCH_REGISTERED_DEVICES": {
      return {...state, fetching: true}
    }
    case "FETCH_REGISTERED_DEVICES_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_REGISTERED_DEVICES_FULFILLED": {
      const deviceCount = state.deviceCount + action.payload.response.data.devices.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(deviceCount, perPage);
      return {
        ...state, 
        fetching: false, 
        fetched: true, 
        devices: action.payload.response.data.devices, 
        pageCount:numberOfPages,
        deviceNextToken:action.payload.response.data.next_token 
      }
    }
    case "ACTIVATE_REGISTERED_DEVICES_FULFILLED":{
      if(action.payload.response.data.device_names) {
        const oldDeviceList = state.devices;
        const newDeviceList = action.payload.response.data.device_names;
        const device = oldDeviceList.map(obj => {
          if(newDeviceList.includes(obj.device_name)) {
            obj.device_attributes.isActivated = "1";
          }
          return obj;
        });
        return { ...state, fetching: false, fetched: true, devices:device } 
      }
    }
    case "ACTIVATE_REGISTERED_DEVICES_REJECTED":{
      return { ...state, fetching: false, error: action.payload }
    }
    case "CLEAR_REGISTERED_DEVICES_DATA":{
      return { ...state, devices: [], deviceCount: 0, fetching: false, fetched: false, error: null, acceptedDevices: [] }
    }
    // case "FETCH_ACCEPTED_DEVICES": {
    //   return {...state, fetching: true}
    // }
    // case "FETCH_ACCEPTED_DEVICES_REJECTED": {
    //   return {...state, fetching: false, error: action.payload}
    // }
    // case "FETCH_ACCEPTED_DEVICES_FULFILLED": {
    //   const deviceCount = state.deviceCount + action.payload.response.data.devices.length;
    //   const perPage = action.payload.request.perPage;
    //   const numberOfPages = getPageCount(deviceCount, perPage);
    //   return {
    //     ...state, 
    //     fetching: false, 
    //     fetched: true, 
    //     acceptedDevices: action.payload.response.data.devices, 
    //     pageCount:numberOfPages
    //   }
    // }
  }
  return state
}
