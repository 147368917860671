import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ActionLoader from "./components/ActionLoader";
import { PrivateRoute } from "./components/Auth/Authorization";
import { Provider } from "react-redux";
import T from "i18n-react";
import store from "./store";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import { jwtRefreshtoken } from './library/utility';
import Loadable from "react-loadable";
import "../css/datepicker.css";
import "../css/bootstrap.css";
import "../css/style.css";
import "../css/baseline.css";
import "../css/react-grid-layout/css/styles.css";
import "../css/react-resizable/css/styles.css";
import "../css/react-datepicker.css";

const Loading = () => <ActionLoader /> ;

const Login = Loadable({
    loader: () => import("./pages/Auth/Login"),
    loading: Loading
});
const Logout = Loadable({
    loader: () => import("./pages/Auth/Logout"),
    loading: Loading
});

const Layout = Loadable({
    loader: () => import("./pages/Layout"),
    loading: Loading
});
 
const app = document.getElementById("app");
const token = localStorage.getItem("iTramsToken");
const userData = localStorage.getItem("userData");
const deviceFilters = localStorage.getItem("deviceFilters");
if (token) {
  let decoded = jwt_decode(token); 
  T.setTexts(require(`../i18n/en.json`));
  if (moment.utc(new Date()) > moment.utc(new Date(decoded.exp * 1000))) {
    store.dispatch({type: 'UN_AUTH'})
  } else {
    store.dispatch({type: 'AUTH_SUCCESS', payload: JSON.parse(userData)});
    store.dispatch({type : 'REFRESH_DEVICE_FILTERS', payload : JSON.parse(deviceFilters)})
    jwtRefreshtoken();
  }
} else {
  T.setTexts(require(`../i18n/en.json`));
}

ReactDOM.render(
  <Provider store={store}>
    <Router basename="/client">
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/logout" name="Logout Page" component={Logout} />
        <PrivateRoute path="/" name="Home" component={Layout} /> 
      </Switch>
    </Router>
  </Provider>,
  app
);
