import { getPageCount, insertObjectAtFirstPosition } from '../../library/utility';

export default function reducer(state = {
  users: [],
  usersCount: 0,
  fetching: false,
  fetched: false,
  error: null,
  deleteStatus:"",
  lockStatus:"",
  pageCount: 0,
  resetUserData:[],
  message : null,
  code : null,
  campaignApprovers : [],
  campaignReviewers : []
}, action) {
  switch (action.type) {
    case "FETCH_USER_DETAILS": {
      return {...state, fetching: true, message : null}
    }
    case "FETCH_USER_DETAILS_FULFILLED": {   
      const userList = action.payload.data.data.users;
      const userCnt = state.usersCount + userList.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(userCnt, perPage);
      return {...state, fetching: false, fetched: true, usersCount:userCnt, users: userList, pageCount:numberOfPages, message : null}
    }
    case "SEARCH_USER_FULFILLED": {  
      let oldUserList = action.payload.data.data.users;
      const searchUser =   action.payload.request.request.textToFilter;
      if(searchUser === "" || searchUser === undefined || searchUser === null){
        state.users = oldUserList;        
      } else {
        const searchUsersList = oldUserList.filter(value => {
        if((value.user_name.trim().toLowerCase() === searchUser.trim().toLowerCase())){
          return value;
        }});
        oldUserList = searchUsersList;
      }
      const userCnt = state.usersCount + action.payload.data.data.users.length;
      return {...state, fetching: false, fetched: true, usersCount:userCnt, users: oldUserList, message : null}
    }
    case "SEARCH_USER_REJECTED": {
      return {...state, fetching: false, error: action.payload, message : null}
    }
    case "CREATE_NEWUSER_FULFILLED": {
      const user = action.payload.data.users;
      const newUser = insertObjectAtFirstPosition(state.users, user);      
      const userCnt = newUser.length;      
      const perPage = action.request.perPage;          
      const numberOfPages = getPageCount(userCnt, perPage);
      return { ...state, fetching: false, fetched: true, users: newUser, pageCount:numberOfPages }
    }
    case "CREATE_NEWUSER_REJECTED": {
      const message = "User Creation Failed";
      return { ...state, error: action.payload, message : message}
    }
    case "CLEAR_USER_DATA": {
      return { ...state, 
        users:[], 
        usersCount: 0, 
        fetching: false, 
        fetched: false, 
        error: null, 
        lockStatus:"", 
        pageCount: 0, 
        resetUserData:[],
        message : null
      }
    }
    case "LOCK_OR_UNLOCK_USER_FULFILLED":{
      const id = action.payload.request.data.user_id;
      if(id !== null) {
        const users = state.users;
        const updatedUser = action.payload.data.data;
        const updatedUsers = users.map(user => user.user_id === updatedUser.user_id ? updatedUser : user);
        return {...state , fetching: false, fetched: true, users:updatedUsers, lockStatus:action.payload.data.status, message : null}
      }
    }
    case "LOCK_OR_UNLOCK_USER_REJECTED":{
      return {...state, fetching: false, error: action.payload, message : null}
    } 
    case "FETCH_USER_DETAILS_REJECTED": {
      return {...state, fetching: false, error: action.payload, message : null}
    }
    case "UPDATE_USER_SUCCESS":{
      const oldUserList = state.users;
      const newUserList = action.payload.data.users;
      const user = oldUserList.map(obj => newUserList.find(o => o.user_id === obj.user_id) || obj);
      return {...state, fetching: false, fetched: true, users:user }
    }
    case "UPDATE_USER_EJECTED":{
      return { ...state, fetching: false, error: action.payload, message : null}
    }
    case "DELETE_USER_SUCCESS": {
      const userID = action.request.data.user_id;
      if(userID !== null) {
        const updatedUsers = state.users;
        const index = updatedUsers.findIndex(user => user.user_id === userID);
        updatedUsers.splice(index, 1);
        const userCnt = updatedUsers.length;        
        const perPage = action.request.perPage;           
        const numberOfPages = getPageCount(userCnt, perPage);
        return { ...state, fetching: false, fetched: true, users: updatedUsers, pageCount: numberOfPages, usersCount:userCnt }
      }
      return { ...state }
    }
    case "DELETE_USER_REJECTED": {
      return {...state, fetching: false, error: action.payload, message : null}
    }
    case "PASSWORD_RESET_SUCCESS": {    
      return {...state, fetching: false, fetched: true, resetUserData:action.payload.response.data, message : null }
    }
    case "PASSWORD_RESET_REJECTED": {
      return {...state, fetching: false, error: action.payload, message : null}
    }
    case "CLEAR_MESSAGES" : {
      return {
        ...state,
        message : null,
        code : null
      }
    }
    case "FETCH_CAMPAIGN_SOFTWARE_APPROVER_SUCCESS" : {
      return {
        ...state,
        campaignApprovers : action.payload.data.users,

      }
    }
    case "FETCH_CAMPAIGN_REVIEWER_SUCCESS" : {
      return {
        ...state,
        campaignReviewers : action.payload.data.users
      }
    }
  }
  return state
}
