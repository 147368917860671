import { getPageCount, insertObjectAtFirstPosition } from "../../library/utility";

export default function reducer(
  state = {
    groupDetails: [],
    groupModulePageCount: 0,
    groupCount: 0,
    fetching: false,
    fetched: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case "FETCH_All_GROUPS": {
      return { ...state, fetching: true };
    }
    case "FETCH_All_GROUPS_FULFILLED": {
      const groupList = action.payload.data.data;
      return { ...state, fetched: true,fetching: false, groupDetails: groupList, groupCount: groupList.length };
    }
    case "FETCH_All_GROUPS_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_GROUP_DETAILS_FULFILLED": {
      const groupList = action.payload.data.data
      const groupCnt = 0 + groupList.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(groupCnt, perPage);
      return {...state, fetching: false, fetched: true, groupCount:groupCnt, groupDetails: groupList, groupModulePageCount:numberOfPages}
    }
    case "FETCH_GROUP_DETAILS_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "CREATE_NEWGROUP_FULFILLED": {
      const group = action.payload.data.data;     
      const perPage = 10;
      const newGroupDetails = insertObjectAtFirstPosition(state.groupDetails, group);
      const groupCount = newGroupDetails.length;
      const numberOfPages = getPageCount(groupCount, perPage);
      return { ...state, fetching: false, fetched: true, groupDetails: newGroupDetails, 
      groupModulePageCount: numberOfPages, groupCount: groupCount };
    }
    case "CREATE_NEWGROUP_REJECTED":{
      return { ...state}
    }
    case "UPDATE_GROUP_DETAILS_SUCCESS": {
      const group_id = action.payload.request.group_id;      
      if(group_id !== null) {
        const oldGroupList = state.groupDetails;        
        const newGroupList = [action.payload.response.data];      
        const group = oldGroupList.map(obj => newGroupList.find(o => o.group_id === obj.group_id) || obj);
        return { ...state, fetching: false, fetched: true, groupDetails:group }
      }
    }
    case "UPDATE_GROUP_DETAILS_REJECTED": {
      return { ...state, fetching: false, error: action.payload}
    }
    case "DELETE_GROUP_SUCCESS": {
      const group_id = action.payload.request.groupID;     
      if(group_id !== null) {
        const oldGroupList = state.groupDetails;
        const newGroupList = [];
        if(oldGroupList !== undefined && oldGroupList.length > 0) {
          for(let i=0;i<=oldGroupList.length-1;i++) {
            if(oldGroupList[i].group_id === group_id) {
               delete oldGroupList[i];
                } else {
                  newGroupList.push(oldGroupList[i]);
                }
            }
        }       
        const groupCnt = newGroupList.length;        
        const perPage = 10;           
        const numberOfPages = getPageCount(groupCnt, perPage);
        return { ...state, fetching: false, fetched: true, groupDetails: newGroupList, groupModulePageCount: numberOfPages, groupCount:groupCnt }
      }
    }
    case "DELETE_GROUP_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "SEARCH_GROUPS_FULFILLED": {  
      let oldGroupList = action.payload.data.data;
      const searchGroup =   action.payload.request.request.textToFilter;
      if(searchGroup === "" || searchGroup === undefined || searchGroup === null){
        state.groupDetails = oldGroupList;        
      } else {
        const searchGroupsList = oldGroupList.filter(value => {
          if((value.group_name.trim().toLowerCase() === searchGroup.trim().toLowerCase())){
            return value;
            }
          });
          oldGroupList = searchGroupsList;
        }
      const groupCnt = state.groupCount + oldGroupList.length;
      return {...state, fetching: false, fetched: true, groupCount:groupCnt, groupDetails: oldGroupList}
    }
    case "SEARCH_GROUPS_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "CLEAR_GROUPS_DATA": {
      return { ...state, 
        groupDetails:[], 
        groupModulePageCount: 0, 
        groupCount: 0, 
        fetching: false, 
        fetched: false,
        error: null
      }
    }
  }
  return state
}
