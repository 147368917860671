import { getPageCount } from '../../library/utility';

const initialState = {
    workFlowApprovals : [],
    workFlowCreatedBy : null,
    workFlowStages : [],
    nextWorkFlowStage : null,
    fetching: false,
    fetched: false,
    error: null,
    workFlowApprovalsCount: 0,
    pageCount: 0,
    workflowHistory:[],
    workflowHistoryFetching:false,
    workflowHistoryFetched:false,
    workflowHistoryModuleCount:0,
    workflowHistoryPageCount:0
}
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case "FETCH_WORKFLOW_APPROVAL" : {
            return { ...state, fetching: true, fetched:false }
        }
        case "FETCH_WORKFLOW_APPROVAL_SUCCESS" : {
            const workFlowApprovalsCount = state.workFlowApprovalsCount === 0 ? action.payload.next_approvals.length : state.workFlowApprovalsCount;
            const perPage = action.perPage;
            const numberOfPages = getPageCount(workFlowApprovalsCount, perPage);
            let workflowData;
            if(action.payload.current_approval === null){
                workflowData=action.payload.next_approvals;
            } else {
                const oldWorkFlowApprovals = state.workFlowApprovals;
                const newWorkFlowApprovals = [action.payload.current_approval];
                workflowData = oldWorkFlowApprovals.map(obj => newWorkFlowApprovals.find(o => o.campaign_id === obj.campaign_id) || obj);
            }
            return { ...state, 
                fetching:false, 
                fetched:true, 
                workFlowApprovals : workflowData, 
                workFlowApprovalsCount: workFlowApprovalsCount, 
                pageCount: numberOfPages 
            }
        }
        case "FETCH_WORKFLOW_APPROVAL_FAILURE" : {
            return { ...state, fetching: false, fetched:false, error: action.payload }
        }
        case "CLEAR_WORKFLOW_APPROVAL_DATA" : {
            return { ...state, fetching: false, fetched:false, workFlowApprovals: [], workFlowApprovalsCount:0, pageCount:0 }
        }
        case "FETCH_WORKFLOW_CREATE_BY_FAILURE" : 
        case "FETCH_WORKFLOW_APPROVAL_STAGES_FAILURE":
        return {
            ...state
        }
        case "FETCH_WORKFLOW_CREATE_BY_SUCCESS" : 
        return {
            ...state,
            workFlowCreatedBy : action.payload
        }
        case "FETCH_WORKFLOW_APPROVAL_STAGES_SUCCESS" : 
        return {
            ...state,
            workFlowStages : action.payload.data
        }
        case "UPDATE_NEXT_WORKFLOW_STAGE" : {
            return { ...state, nextWorkFlowStage : action.payload.nextWorkFlowStage }
        }
        case "FETCH_WORKFLOW_HISTORY_SUCCESS" : {
            const moduleCt = state.workflowHistoryModuleCount + action.payload.next_approvals.length;
            const perPage = 10;
            const numberOfPages = getPageCount(moduleCt, perPage);
            return { ...state, 
                workflowHistoryFetching:false, 
                workflowHistoryFetched:true, 
                workflowHistory : action.payload.next_approvals, 
                workflowHistoryModuleCount: moduleCt, 
                workflowHistoryPageCount: numberOfPages 
            }
        }
        case "FETCH_WORKFLOW_HISTORY_FAILURE" : {
            return { ...state, workflowHistoryFetching: false, workflowHistoryFetched:false, error: action.payload }
        }     
    }
    return state;
}