import { getPageCount, insertObjectAtFirstPosition, fetchsortedArray } from '../../library/utility';

export default function reducer(state = {
  modules: [],
  fetching: false,
  fetched: false,
  error: null,
  moduleCount: 0,
  pageCount: 0
}, action) {
  switch (action.type) {
    case "FETCH_MODULE": {
      return {...state, fetching: true}
    }
    case "FETCH_MODULE_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_MODULE_FULFILLED": {
      const request = action.payload.request;
      const response = action.payload.response.data;
      const moduleCt = state.moduleCount + response.length;
      const perPage = request.perPage;
      const numberOfPages = getPageCount(moduleCt, perPage);
      let packageList = (request.sortColumnName !== undefined && request.sortOrder !== undefined) ? 
        fetchsortedArray(response, request.sortColumnName, request.sortOrder) : response;
      return {...state, fetching: false, fetched: true, modules: packageList, moduleCount: moduleCt, pageCount: numberOfPages}
    }
    case "CLEAR_MODULE_DATA": {
      return {...state, modules: [], fetching: false, fetched: false, error: null, moduleCount: 0, pageCount: 0}
    }
    case "CREATE_MODULE_TYPE_FULFILLED": {
      const module_type = action.payload.data.data.software_modules;
      const newmodule = insertObjectAtFirstPosition(state.modules, module_type);
      const moduleCt = state.moduleCount + module_type.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(moduleCt, perPage);
      return { ...state, fetching: false, fetched: true, modules: newmodule, moduleCount: moduleCt, pageCount: numberOfPages }
    }
    case "CREATE_MODULE_TYPE_REJECTED": {
      return { ...state }
    }
    case "UPDATE_MODULE_TYPE_FULFILLED":{
      const id = action.payload.request.module_id;
      if(id !== null) {
        const oldModuleList = state.modules;
        const newModuleList = [action.payload.data.data];
        const updatedModuleList = oldModuleList.map(obj => newModuleList.find(o => o.module_id === obj.module_id) || obj);
        return { ...state, fetching: false, fetched: true, modules:updatedModuleList }
      }
    }
    case "UPDATE_MODULE_TYPE_REJECTED":{
      return { ...state, fetching: false, error: action.payload }
    }
  }
  return state
}
