import { getPageCount } from '../../library/utility';

export default function reducer(state = {
  selected_campaign: [],
  groups: [],
  rollback_groups: [],
  fetching: false, 
  fetched: false, 
  error: null,
  groupsCount: 0,
  rollback_groupsCount: 0,
  pageCount: 0,
  rollback_pageCount: 0,
  deviceCountData:[],
  deviceCountFetching:false,
  deviceCountFetched:false
}, action) {
  switch (action.type) {
    case "CLEAR_SELECTED_CAMPAIGN_DATA": {
      return { ...state, selected_campaign: [] }
    }
    case "FETCH_SELECTED_CAMPAIGN_REJECTED": {
      return {...state, error: action.payload}
    }
    case "FETCH_SELECTED_CAMPAIGN_FULFILLED": {
      return {...state, selected_campaign: action.payload.response.data }
    }
    case "FETCH_CAMPAIGN_GROUP": {
      return {...state, fetching: true, fetched: false }
    }
    case "FETCH_CAMPAIGN_GROUP_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_CAMPAIGN_GROUP_FULFILLED": {
      const groupsCt = state.groupsCount + action.payload.response.data.data.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(groupsCt, perPage);
      return {...state, fetching: false, fetched: true, groupsCount:groupsCt, groups: action.payload.response.data.data, pageCount:numberOfPages }
    }
    case "CLEAR_CAMPAIGN_GROUP_DATA": {
      return { ...state, 
        selected_campaign: [], 
        groups: [], 
        rollback_groups: [], 
        fetching: false, 
        fetched: false, 
        error: null, 
        groupsCount: 0, 
        pageCount: 0, 
        rollback_groupsCount: 0, 
        rollback_pageCount: 0
      }
    }
    case "FETCH_CAMPAIGN_ROLLBACK_GROUP_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_CAMPAIGN_ROLLBACK_GROUP_FULFILLED": {
      const groupsCt = state.rollback_groupsCount + action.payload.response.data.data.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(groupsCt, perPage);
      return {...state, fetching: false, fetched: true, rollback_groupsCount:groupsCt, rollback_groups:action.payload.response.data.data,  rollback_pageCount:numberOfPages }
    }
    //used in workflow management
    case "CLEAR_CAMPAIGN_DEVICE_COUNT": {
      return { ...state, deviceCountData: [],deviceCountFetching:true, deviceCountFetched:false }
    }
    case "FETCH_CAMPAIGN_DEVICE_COUNT": {
      return {...state, deviceCountFetching:true, deviceCountFetched:false }
    }
    case "FETCH_CAMPAIGN_DEVICE_COUNT_REJECTED": {
      return {...state, error: action.payload, deviceCountFetching:false, deviceCountFetched:false }
    }
    case "FETCH_CAMPAIGN_DEVICE_COUNT_FULFILLED": {
      const devcieStatus = action.payload.data;
      const seriesData = [
        { name: 'CANCELLED', y: parseInt(devcieStatus.canceled), color: '#d4d5d6' }, 
        { name: 'INPROGRESS', y: parseInt(devcieStatus.in_progress), color: '#0ae' }, 
        { name: 'FAILED',  y: parseInt(devcieStatus.failed), color: '#dc3545' }, 
        { name: 'SUCCEEDED', y:parseInt(devcieStatus.succeeded), color: '#6cc788' }, 
        { name: 'QUEUED', y: parseInt(devcieStatus.queued), color: '#8fd2f9' },
        { name: 'REJECTED', y: parseInt(devcieStatus.rejected), color: '#a20909' },
        { name: 'REMOVED', y: parseInt(devcieStatus.removed), color: '#fd7e14' },
        { name: 'TIMED OUT', y: parseInt(devcieStatus.timed_out), color: '#ffc107' }]
      return {...state, deviceCountData:seriesData,deviceCountFetching:false, deviceCountFetched:true  }
    }
  }
  return state
}

