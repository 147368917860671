export default function reducer(state = {
    isAuthenticated: false,
    isIdeal: false,
    fetching: false,
    fetched: false,
    error: null,
    forgotPassFlg: false,
    forgotPassError: false,
    loginFailed: false,
    questions: [],
    secQuesFlg: true,
    passResetLogin: false,
    userName: '',
    changePassError: false,
    selectedParent: '',
    selectedPath: '',
    loginMes: '',
    forgotPassFetching: false,
    forgotPassMess: '',
    loginSuccess: false,
    resetPassFetching: false,
    resetPassMess: '',
    selectedBrand: '',
    loginDetails:{}
}, action) {
    switch (action.type) {
        case "AUTH": {
            return { ...state, loginFailed: false, fetching: true }
        }
        case "AUTH_REJECTED": {
            return { ...state, fetching: false, loginFailed: true, loginSuccess: false, error: action.payload }
        }
        case "AUTH_REJECTED_DISP_MES": {
            return { ...state, loginMes: action.payload, loginSuccess: false }
        }
        case "AUTH_SUCCESS": {
            return { ...state, 
                fetching: false, 
                fetched: true, 
                loginFailed: false, 
                isAuthenticated: true, 
                loginSuccess: true, 
                loginMes: '', 
                loginDetails: action.payload 
            }
        }
        case "UN_AUTH": {
            return { ...state, fetching: false, fetched: false, isAuthenticated: false }
        }
        case "INITIAL_LOGIN": {
            return { ...state, secQuesFlg: action.payload.questionFlag, passResetLogin: action.payload.initialLogin, userName: action.payload.userName, loginSuccess: false, loginMes: '', fetching: false, fetched: true, loginFailed: false }
        }
        case "UPDATE_PARENT": {
            localStorage.setItem('parentModule', action.payload.data.module);
            localStorage.setItem('parentPath', action.payload.data.path);
            return { ...state }
        }
        case "CLEAR_ERR_MSG": {
            return { ...state, loginFailed: false, loginMes: "" }
        }
        case "SET_LOGIN_BRAND": {
            return { ...state, selectedBrand: action.payload }
        }
        //added to stop generating refresh token when user is ideal
        case "FETCH_REFRESH_TOKEN_REJECTED": {
            return { ...state, isIdeal: true }
        }
        case "FETCH_REFRESH_TOKEN_SUCCESS": {
            return { ...state, isIdeal: false }
        }
    }
    return state
}