import update from 'immutability-helper';
export default function reducer(
  state = {
    pipItems: []
  }, action) {
    switch (action.type) {
      case "ADD_TO_PIP": {
        let newPipItem=state.pipItems;
        let index=-1;
        if(state.pipItems.length==0){
          newPipItem=  update(state.pipItems, {$push: [action.payload]});
        } else{
          state.pipItems.map((item,i)=> { 
            if(Object.values(item)[0]==Object.values(action.payload)[0]){
              index=  i;
            }          
          });
          if(index==-1){
            newPipItem=  update(state.pipItems, {$push: [action.payload]});
          }
        }
        return { ...state, pipItems:newPipItem  };
      }
      case "REMOVE_PIP": {
        let newPipItem=  update(state.pipItems, {$splice: [[action.payload, 1]]})
        return { ...state, pipItems:newPipItem  };
      }
      case "FETCH_PIP": {
        return { ...state, pipItems: state.pipItems }
      }
    }
    return state;
  }
