import React from 'react';

const ActionLoader = () =>  {
    return (
      <div className="init-loader">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    );
}
export default ActionLoader;
