import { getPageCount, fetchLastinsertedArrayFirst } from '../../library/utility';

export default function reducer(state = {
  fetching: false, 
  fetched: false, 
  error: null,
  campSummary: [],
  campaignCount:0,
  pageCount: 0,
}, action) {
  switch (action.type) {
    case "FETCH_CAMPAIGN_SUMMARY": {
      return {...state, fetching: true}
    }
    case "FETCH_CAMPAIGN_SUMMARY_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_CAMPAIGN_SUMMARY_FULFILLED": {
      //filter out software which does not have any files uploaded
      //const filteredCampaign = action.payload.data.data.filter(obj => obj.status === 'SUCCEEDED');
      const filteredCampaign = action.payload.response.data.data;
      const campaignCt = state.campaignCount + filteredCampaign.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(campaignCt, perPage);
      const campaignList = fetchLastinsertedArrayFirst(filteredCampaign);
      return {...state, fetching: false, fetched: true, campSummary: campaignList, pageCount:numberOfPages}
    }
    case "CLEAR_CAMPAIGN_SUMMARY_DATA": {
      return { ...state, fetching: false, fetched: false, error: null, campSummary: [], campaignCount:0, pageCount: 0 }
    }
    case "FETCH_CAMPAIGN_FILTER": {
      return {...state, fetching: true}
    }
    case "FETCH_CAMPAIGN_FILTER_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_CAMPAIGN_FILTER_FULFILLED": {
      const filteredCampaign = action.payload.response.data.data;
      const campaignCt = state.campaignCount + filteredCampaign.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(campaignCt, perPage);
      const campaignList = fetchLastinsertedArrayFirst(filteredCampaign);
      return {...state, fetching: false, fetched: true, campSummary: campaignList, pageCount:numberOfPages}
    }
  }
  return state
}
