import { getPageCount, insertObjectAtFirstPosition } from "../../library/utility";

export default function reducer(
  state = {
    roleDetails: [],
    roleCount: 0,
    pageCount: 0,
    fetching: false,
    fetched: false,
    error: null,
    campaignWorkFlowSubFeatures : []   
  },
  action
) {
  switch (action.type) {
    case "FETCH__ROLES": {
      return { ...state, fetching: true };
    }
    case "FETCH_ROLES_FULFILLED": {
      const roles = action.payload.data;
      const roleCount =  roles.length;
      const perPage = action.request.perPage;
      const numberOfPages = getPageCount(roleCount, perPage);
      return { ...state, fetched: true, roleDetails: roles, roleCount: roleCount, pageCount:numberOfPages };
    }
    case "FETCH_ROLES_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "FETCH_All_ROLES_FULFILLED": {
      const roles = action.payload.data;
      return { ...state, fetched: true, roleDetails: roles };
    }
    case "FETCH_All_ROLES_REJECTED": {
      return { ...state, fetching: false, error: action.payload };
    }
    case "INSERT_ROLE": {
      const role = action.payload.data;
      const newRoleDetails = insertObjectAtFirstPosition(state.roleDetails, role);
      const roleCount = state.roleCount + 1; 
      const perPage = action.request.perPage;          
      const numberOfPages = getPageCount(roleCount, perPage);
      return { ...state, fetched: true, roleDetails: newRoleDetails,  roleCount: roleCount, pageCount:numberOfPages };
    }
    case "UPDATE_ROLE_FULFILLED": {
      const role_id = action.payload.data.role_id; 
      if(role_id !== null) {
        const oldRoleList = state.roleDetails;  
        const newRoleList = [action.payload.data];  
        const roles = oldRoleList.map(obj => newRoleList.find(o => o.role_id === obj.role_id) || obj);
        const roleCount = roles.length; 
        const perPage = action.request.perPage;          
        const numberOfPages = getPageCount(roleCount, perPage);
        return {...state, fetching: false, fetched: true, roleDetails:roles, pageCount:numberOfPages }
      }
    }
    case "UPDATE_ROLE_REJECTED": {
      return { ...state, fetching: false, error: action.payload }
    }
    case "DELETE_ROLE_SUCCESS": {
      const role_id = action.payload.request.roleID;    
      if(role_id !== null) {
        const oldRoleList = state.roleDetails;
        const newRoleList = [];
        if(oldRoleList !== undefined && oldRoleList.length > 0) {
          for(let i=0;i<=oldRoleList.length-1;i++) {
            if(oldRoleList[i].role_id === role_id) {
               delete oldRoleList[i];
                } else {
                  newRoleList.push(oldRoleList[i]);
                }
            }
        }       
        const roleCnt = newRoleList.length;    
        const perPage = action.payload.request.perPage;          
        const numberOfPages = getPageCount(roleCnt, perPage);    
        return {...state, fetching: false, fetched: true, roleDetails: newRoleList, roleCount:roleCnt, pageCount:numberOfPages}
      }
    }
    case "DELETE_ROLE_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "CLEAR_ROLE_DATA": {
      return {...state, roleDetails: [], roleCount: 0, pageCount: 0, fetching: false, fetched: false, error: null }
    }
    case "FETCH_CAMPAIGN_WORKFLOW_SUBFEATURES_SUCCESS" : {
      return { ...state, campaignWorkFlowSubFeatures : action.payload.data }
    }
    case "FETCH_CAMPAIGN_WORKFLOW_SUBFEATURES_ERROR" : {
      return { ...state }
    }
  }
  return state
}
