import { getPageCount, insertObjectAtFirstPosition, filterByValue, fetchsortedArray, fetchLastinsertedArrayFirst } from '../../library/utility';

export default function reducer(state = {
  softwares: [],
  fetching: false,
  fetched: false,
  error: null,
  softwareCount: 0,
  pageCount: 0,
  baseVersion: [],
  baseVersionFetching: false,
  baseVersionFetched: false
}, action) {
  switch (action.type) {
    case "FETCH_SOFTWARE": {
      return {...state, fetching: true}
    }
    case "FETCH_SOFTWARE_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_SOFTWARE_FULFILLED": {
      const request = action.payload.request;
      const response = action.payload.response.data.data;
      const softwareCt = state.softwareCount + response.length;
      const perPage = request.perPage;
      const numberOfPages = getPageCount(softwareCt, perPage);
      const softwareList =  (request.sortColumnName !== undefined && request.sortOrder !== undefined) ? 
        fetchsortedArray(response, request.sortColumnName, request.sortOrder) : fetchLastinsertedArrayFirst(response);
      return {...state, fetching: false, fetched: true, softwares: softwareList, softwareCount: softwareCt, pageCount:numberOfPages}
    }
    case "FETCH_SEARCH_SOFTWARE": {
      return {...state, fetching: true}
    }
    case "FETCH_SEARCH_SOFTWARE_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "FETCH_SEARCH_SOFTWARE_FULFILLED": {
      const softwareText= action.payload.request.softwareTextSearch;
      const softwares = action.payload.response.data.data;
      let softwareList = fetchLastinsertedArrayFirst(softwares);
      if(softwareText !== undefined && softwareText !== null && softwareText !== "") {
        softwareList = filterByValue(softwares, softwareText.replace('*',''));
          //console.log(Object.keys(item)) //to get object keys
          //console.log(Object.values(item)) //to get object values
      }
      const softwareCt = state.softwareCount + softwareList.length;
      const perPage = action.payload.request.perPage;
      const numberOfPages = getPageCount(softwareCt, perPage);
      return {...state, fetching: false, fetched: true, softwares: softwareList, softwareCount: softwareCt, pageCount:numberOfPages}
    }
    case "CLEAR_SOFTWARE_DATA": {
      return {...state, softwares: [], fetching: false, fetched: false, error: null, softwareCount: 0, pageCount: 0}
    }
    case "CREATE_SOFTWARE_FULFILLED": {
      const software = action.payload.data;
      const newSoftware = insertObjectAtFirstPosition(state.softwares, software);
      const count = [];
      count.push(action.payload.data);
      const softwareCt = state.softwareCount + count.length;
      const perPage = action.perPage;
      const numberOfPages = getPageCount(softwareCt, perPage);
      return { ...state, fetching: false, fetched: true, softwares: newSoftware, softwareCount: softwareCt, pageCount:numberOfPages }
    }
    case "CREATE_SOFTWARE_REJECTED": {
      return {...state, fetching: false, error: action.payload}
    }
    case "UPDATE_SOFTWARE_SUCCESS": {
      const id = action.payload.item.id;
      const newSoftwareList = [action.payload.response.data];
      const oldSoftwareList = state.softwares;
      if(id !== null) {
        const software = oldSoftwareList.map(obj => newSoftwareList.find(o => o.id === obj.id) || obj)
        return { ...state, fetching: false, fetched: true, softwares:software }
      }
    }
    case "UPDATE_SOFTWARE_REJECTED":{
      return { ...state, fetching: false, error: action.payload}
    }
    case "FETCH_BASE_VERSION": {
      return { ...state, baseVersionFetching: true, baseVersionFetched: false }
    }
    case "FETCH_BASE_VERSION_REJECTED": {
      return {...state, baseVersionFetching: false, error: action.payload}
    }
    case "FETCH_BASE_VERSION_FULFILLED": {
      return { ...state, baseVersionFetching: false, baseVersionFetched: true, baseVersion: action.payload.data }
    }
  }
  return state
}
